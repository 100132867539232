/* eslint-disable react/jsx-filename-extension, react/prop-types, max-len */
import { createContext, useContext } from 'react';

const ConfigContext = createContext(null);

export const ConfigProvider = ({ config, children }) => (
  <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
);

/**
 * @typedef { 'BodyType' | 'Brand' | 'Color' | 'Kms' | 'Location' | 'Model' | 'Price' | 'State' | 'Transmission' | 'Year' } SearchFilter
 */

/**
 * @typedef { 'Autocomplete' | 'Card' | 'Checkbox' | 'Histogram' } SearchFilterType
 */

/**
 * @typedef { 'normal' | 'italic' | 'oblique' } FontStyle
 */

/**
 * @typedef { Object } FontFace
 * @property { String } fontFamily
 * @property { FontStyle } fontStyle
 * @property { String } fontWeight
 * @property { String } src
 */

/**
 * @typedef { Object } OpenGraphConfig
 * @property { string } description
 * @property { string } image
 * @property { string } siteName
 * @property { string } title
 * @property { string } url
 */

/**
 * @typedef { Object } LinkConfig
 * @property { string } display
 * @property { string } href
 */

/**
 * @typedef { Object } SearchFilterConfig
 * @property { SearchFilter } filter
 * @property { SearchFilterType } type
 * @property { boolean } enabled
 */

/**
 * @typedef { Object } StylesConfig
 * @property { string } askButtonColor
 * @property { string } backgroundColor
 * @property { string } breadcrumbsColor
 * @property { string } drawerColor
 * @property { string } footerColor
 * @property { string } headerColor
 * @property { string } mercadolibreColor
 * @property { string } navLinkColor
 * @property { string } oddRowColor
 * @property { string } previewPricingColor
 * @property { string } pricingColor
 * @property { string } primaryColor
 * @property { string } secondaryColor
 * @property { string } separatorColor
 * @property { string } themeColor
 * @property { string } whatsappColor
 */

/**
 * @typedef { Object } CopiesConfig
 * @property { string } aboutUs
 * @property { string } askAlert
 * @property { string } defaultAsk
 * @property { string } defaultWhatsappMessage
 * @property { string } header
 * @property { string } title
 * @property { string } soldBy
 */

/**
 * @typedef { Object } LocaleConfig
 * @property { string } language
 */

/**
 * @typedef { Object } NavigationLinkConfig
 * @property { string } text
 * @property { string } href
 */

/**
 * @typedef { Object } Config
 * @property { CopiesConfig } copies
 * @property { LinkConfig[] } contactLinks
 * @property { string } drawerLogo
 * @property { string[] } externals
 * @property { string } favicon
 * @property { FontFace } fontFace
 * @property { string } logo
 * @property { LinkConfig[] } legalLinks
 * @property { LocaleConfig } locale
 * @property { NavigationLinkConfig[] } navigationLinks
 * @property { OpenGraphConfig } openGraph
 * @property { SearchFilterConfig[] } searchFilters
 * @property { 'Normal' | 'Compact' } searchResultType
 * @property { StylesConfig } styles
 * @property { string } logoLink
 * @property { string | null } secondLogo
 * @property { string } mobileLogo
 * @property { boolean } hideSellerInfo
 * @property { string } listedCertificationBadge
 * @property { string } descriptionCertificationBadge
 */

/**
 * @returns { Config }
 */
export default function useConfig() {
  const config = useContext(ConfigContext);

  return config;
}
